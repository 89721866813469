import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ListContext, MyContext } from "./App";
import {
  PrimaryText,
  Td,
  StyledTr,
  TableText1,
  TableText2,
  Th,
  HelpText,
  StyledLink,
  PrimaryTextBlue,
  OrangeButton,
  Row100,
  OrangeText,
  BlueText,
  BlueButton,
  LinkTextBlue,
  BoxWithLabelNoM,
  BlockLabelGrow,
  BlockLabel,
} from "./styleCustom";
import AuthHelperMethods from "./AuthHelperMethods";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { FormRight, ModalWarning } from "./formComponents";
import Payment from "./payment";
import CheckoutSavedPaymentForm from "./paymentSavedForm";
import {
  currentDayToDB,
  dateObjToDBDate,
  dateObjToDisplayDate,
  calculate_expiry_date,
  todayDisplay,
  stringToDateObj,
  dateISOtoStringMed,
  dateISOtoStringShort,
} from "./momentUtils";
import PrivateRoute from "./privateRoute";
import { divide, remove } from "lodash";
import COLORS, { nametagStyle, buttonOrangeStyle, buttonBlueStyle } from "./styleConstants";
import { MemberLinks } from "./clientHomePage";
import SearchFilterDates from "./searchFilterDates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATEPICKER_DATE_DISPLAY } from "./UserSettings";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import {
  TableHeader2,
  MyTextInput,
  MyTextAreaInput,
  ModalHeaderBlue,
  ButtonOrange,
  ButtonBlue,
} from "./formComponents";
import { Circle, CircleSolid, Trash, EditIconPlain } from "./icons";
import { useAlert } from "react-alert";
import { v4 as uuidv4 } from "uuid";

import ManualPurchase from "./manualPurchaseForm";
import PackageList from "./packageList";
import PackageDetailsEdit from "./packageDetailsEdit";

const Auth = new AuthHelperMethods();

const OnlinePurchase = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();
  const alert = useAlert();
  const [warning, setWarning] = useState("");

  const [productList, setProductList] = useState([]);

  const [currentProduct, setCurrentProduct] = useState({});
  const [validFrom, setValidFrom] = useState(moment().toDate());
  const [purchaseDate, setPurchaseDate] = useState(moment().toDate());
  const [expiry, setExpiry] = useState(moment().toDate());
  const [cartProducts, setCartProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [counter, SetCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showManual, setShowManual] = useState(false);
  const [price, setPrice] = useState();
  const [count, setCount] = useState();
  const [itemNote, setItemNote] = useState();
  const [wait, setWait] = useState(false);
  const chargeFee = mystate.farmDefaults.stripe_convenience_fee > 0 ? true : false;
  const feeAmount = mystate.farmDefaults.stripe_convenience_fee;
  const [credits, setCredits] = useState([]);

  //useFetch("products/index", "PRODUCTS_GET", "dispatchlist", params);

  useEffect(
    () => {
      return () => {
        dispatch2(["CLEAR_CART"]);
        dispatch2(["SET_CURRENT_PRODUCT", {}]);
        dispatch2(["SET_PAYMENT_INTENT", {}]);
        dispatch2(["SET_PAYMENT_METHODS", []]);
      };
    },
    //eslint-disable-next-line
    []
  );
  useEffect(() => {
    const fetchData = async () => {
      let params = {};
      // params.student = listState.currentClient.student_id;
      params.students = listState.members.map((member) => member.student_id);
      console.log(params);
      const result = await Auth.api(`items/credits`, "get", params);
      setCredits(result.data.credits);
    };
    fetchData();
  }, []);

  const onCloseModal = () => {
    console.log("ON CLOES MODAL");
    setShowModal(false);
    dispatch2(["EDIT_CART", listState.currentProduct]); //* update cart with product changes *//
  };

  const stripeFeeTotal = () => {
    var stripeTotal = parseFloat(total * 1.029 + 0.3);

    stripeTotal = Number(stripeTotal).toFixed(2);
    return stripeTotal;
  };

  const handleClickEditProduct = (productIn) => {
    console.log(productIn);
    console.log(stringToDateObj(productIn.purchase_date));
    dispatch2(["SET_CURRENT_PRODUCT", productIn]);
    setShowModal(true);
  };

  const removeProduct = (productin) => {
    console.log(productin);
    let stripe_convenience_fee = mystate.farmDefaults.stripe_convenience_fee;
    dispatch2(["REMOVE_FROM_CART", productin, stripe_convenience_fee]);
  };

  const checkout = () => {
    //** also has link  *//
    if (listState.cartTotal < parseFloat(0.01)) alert("cart total is empty");
  };

  const cancelCheckout = () => {
    dispatch2(["CLEAR_CART"]);
  };

  if (!listState.products) return null;
  return (
    <>
      <div style={{ display: "flex", flex: "1 1 100%", flexWrap: "wrap" }}>
        <MemberLinks verb={""} page={"Purchase Package"} link={"online"} />
        <HelpText>
          {
            "To purchase a package for another account member on the same transaction, click their name where listed under Account Members."
          }
        </HelpText>
        <div style={{ fontSize: 16 }}>{"Click on package below to add to cart"}</div>
      </div>
      <div
        className="wrapper"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flex: "1 1 70%",
          alignItems: "flex-start",
        }}
      >
        {1 == 2 && (
          <div>
            {credits.map((credit) => (
              <div key={credit.id}>{credit.price}</div>
            ))}
          </div>
        )}
        <div
          className="cart"
          style={{
            display: "flex",

            flexWrap: "wrap",
            borderx: "solid",
            marginLeft: 30,
            xminWidth: 250,
            maxWidth: 300,
            orderx: 1,
          }}
        >
          <CartList handleClickEditProduct={handleClickEditProduct} removeProduct={removeProduct} />
          <CartTotal />

          <PayButtons {...props} />
        </div>
        <PackageList />
        <PackageDetailsEdit onCloseModal={onCloseModal} showModal={showModal} setShowModal={setShowModal} />
      </div>
      <PrivateRoute exact path={`${props.match.url}/manual`} component={ManualPurchase} />
      <PrivateRoute
        exact
        path={`${props.match.url}/manualInvoice`}
        component={ManualPurchase}
        thisisinvoice={"thisisinvoice"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flex: "1 1 100%",
          minHeight: 500,
        }}
      >
        <PrivateRoute exact path={`${props.match.url}/payments`} component={Payment} />
        <PrivateRoute exact path={`${props.match.url}/paymentsSaved`} component={CheckoutSavedPaymentForm} />
      </div>
    </>
  );
};

export const CartList = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  console.log(listState.cartProducts);
  return (
    <div style={{ minWidth: 250 }}>
      {" "}
      <div>
        {listState.cartProducts.length > 0 && <div style={{ fontSize: 20, marginBottom: 40 }}>Checkout Cart</div>}
      </div>
      {Auth.getUserType() == "ADM" && listState.cartProducts.length > 0 && (
        <HelpText>{"Admins may click on the blue edit icon to change the package details"}</HelpText>
      )}
      {listState.cartProducts.length > 0 &&
        listState.cartProducts.map((product) => (
          <>
            <div
              className="eachPurchase"
              key={product.counter}
              style={{
                ...nametagStyle,
                display: "flex",
                flex: "1 1 100%",

                justifyContent: "space-between",

                flexWrap: "wrap",
              }}
            >
              <div
                className="topLine"
                style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", flex: "1 1 100%" }}
              >
                <div style={{ fontWeight: "bold", display: "flex", flex: "1 1 100%" }}>{product.product_name}</div>

                <div className="editTrashIcons" style={{ display: "flex", flexDirection: "row" }}>
                  {Auth.getUserType() == "ADM" && (
                    <div
                      className="editProduct"
                      onClick={() => props.handleClickEditProduct(product) || null}
                      style={{ margin: 5 }}
                    >
                      <EditIconPlain color={COLORS.blue} />
                    </div>
                  )}
                  <div className="removeProduct" onClick={() => props.removeProduct(product)} style={{ margin: 5 }}>
                    <Trash />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flex: "1 1 100%" }}>{`${product.student_name} `}</div>
              <div style={{ display: "flex", flex: "1 1 100%" }}>{product.registration_title || ""}</div>
              <div
                className="productDetails"
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  justifyContent: "space-between",
                  color: COLORS.textgrey,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <div>{`purchased`}</div>
                  <div> {dateISOtoStringShort(product.purchase_date)}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <div>{`starts`}</div>
                  <div> {dateISOtoStringShort(product.package_start_date)}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <div>{`expires`}</div>
                  <div> {dateISOtoStringShort(product.package_expiry_date)}</div>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <div>{`count`}</div>
                  <div> {product.start_count}</div>
                </div>
              </div>
              {1 == 2 && product.is_auto && (
                <div style={{ display: "flex", flexDirection: "row", flexWeight: "bold", paddingTop: 3 }}>
                  {`This package renews on  ${dateISOtoStringMed(product.package_renew_date)} `}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: "1 1 100%",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {" "}
                {`${mystate.farmDefaults.currency_symbol}${Number(product.product_price).toFixed(2)}`}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export const CartTotal = () => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  if (listState.cartProducts.length < 1) return null;

  return (
    <div className="ListSummary" style={{ display: "flex", flexDirection: "column", flex: "1 1" }}>
      {!mystate.farmDefaults.stripe_convenience_fee > 0 && (
        <div
          className="total"
          style={{
            display: "flex",
            flex: "1 1 100%",
            marginTop: 5,
            padding: 2,
            justifyContent: "space-between",
          }}
        >
          {listState.cartProducts.length > 0 && (
            <>
              {" "}
              <div>{"Subtotal"}</div>
              <div style={{ textAlign: "right" }}>{listState.cartSubtotal}</div>
            </>
          )}
        </div>
      )}
      <div
        className="salestax"
        style={{
          display: "flex",
          flex: "1 1 100%",

          padding: 2,
          justifyContent: "space-between",
        }}
      >
        {listState.cartProducts.length > 0 && mystate.farmDefaults.tax_percent > 0 && (
          <>
            {" "}
            <div>{`Sales Tax ${parseFloat(mystate.farmDefaults.tax_percent * 100)}%`}</div>
            <div style={{ textAlign: "right" }}>{listState.cartTax}</div>
          </>
        )}
      </div>
      <div
        className="stripefee"
        style={{
          display: "flex",
          flex: "1 1 100%",

          padding: 2,
          justifyContent: "space-between",
        }}
      >
        {listState.cartProducts.length > 0 && mystate.farmDefaults.stripe_convenience_fee > 0 && (
          <>
            {" "}
            <div>{`Convenience Fee if paying by card : ${parseFloat(
              mystate.farmDefaults.stripe_convenience_fee * 100
            )}%`}</div>
            <div style={{ textAlign: "right" }}>{listState.cartFee}</div>
          </>
        )}
      </div>
      <div
        className="stripefeeplustotal"
        style={{
          ...nametagStyle,
          display: "flex",
          flex: "1 1 100%",
          marginBottom: 50,
          justifyContent: "space-between",
          fontSize: 16,
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        {listState.cartProducts.length > 0 && (
          <>
            {" "}
            <div style={{ fontSize: 16, fontWeight: "bold" }}>{"Total"}</div>
            <div
              style={{ textAlign: "right", fontSize: 16, fontWeight: "bold" }}
            >{`${mystate.farmDefaults.currency_symbol}${listState.cartTotal}`}</div>
          </>
        )}
      </div>
    </div>
  );
};

export const PayButtons = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();

  const checkout = () => {
    //** also has link  *//
    if (listState.cartTotal < parseFloat(0.01)) alert("cart total is empty");
  };

  const cancelCheckout = () => {
    dispatch2(["CLEAR_CART"]);
  };

  const offlinePayment = async () => {
    try {
      dispatch2(["ADD_CART_FEE", mystate.farmDefaults.stripe_convenience_fee]);

      let params = {
        total: parseInt(listState.cartTotal * 100),
        account_holder: listState.accountHolder.people_bill_person_name,
        student: listState.currentClient.person_name,
        account_id: listState.accountHolder.id,
        customer_id: listState.accountHolder.stripe_customer_id,
      };
      console.log("*************createPaymentIntent*************");
      console.log(params);
      const result = await Auth.api("payments/createPaymentIntentNoCard/", "post", params);
      console.log(result);
      console.log(result.data.payment_intent.client_secret);
    } catch (error) {
      console.log(error.message);
      //  alert.show(error.message, { timeout: 3000, type: "error" });
    }
  };

  const checkForSavedPayment = async () => {
    //! check for student_id - issues where there is no student_id and card payment goes through but then package is not created

    if (listState.cartProducts.filter((x) => !x.student_id).length > 0) {
      alert("There was an error with your cart. Please start again");
      cancelCheckout();
      return;
    }
    if (listState.cartTotal < parseFloat(0.01)) {
      alert("cart total is empty");

      return;
    }

    try {
      dispatch({
        type: "FETCH_INIT",
      });
      let params = {
        total: parseInt(listState.cartTotal * 100),
        account_holder: listState.accountHolder.people_bill_person_name,
        student: listState.currentClient.person_name,
        account_id: listState.accountHolder.id,
        person_id: listState.accountHolder.id,
      };
      const result = await Auth.api("payments/createPaymentIntentAndCustomer/", "post", params);

      dispatch2(["SET_PAYMENT_INTENT", result.data.payment_intent]);
      dispatch2(["SET_PAYMENT_METHODS", result.data.payment_methods]);
      if (result.data.payment_methods.length == 0) history.push(`${props.match.url}/payments`);
      else history.push(`${props.match.url}/paymentsSaved`);
    } catch (e) {
      console.log(e.message);
      // console.log("[error]", result.error);
      dispatch({
        type: "FETCH_ERROR",
      });
      alert("Sorry - there has been an error with your transaction and it has not been processed.");
    }
  };

  if (listState.cartProducts.length < 1) return null;
  return (
    <div
    className="payAndCancelButtons"

      style={{
        display: "flex",
        justifyContent: "center",
        flex: "1 1 100%",
        flexDirection: "column",
      }}
    >
      <div
              className="payButtonContainer"

        style={{
          display: "flex",
          justifyContent: "center",
          flex: "1 1 100%",
          flexWrap: "wrap",

        }}
      >
        {mystate.farmDefaults.stripe_account_id &&
          mystate.farmDefaults.stripe_account_id.length > 3 &&
          !props.invoice && (
            <ButtonOrange
              onClick={() => checkForSavedPayment()}
              label={Auth.getUserType() == "ADM" ? "Admin Online Card Payment" : "Pay Now"}
            />
          )}
        {Auth.getUserType() == "ADM" && !props.invoice && (
          <Link to={`${props.match.url}/manual`}>
            <ButtonOrange label={"Manual Payment"} />
          </Link>
        )}
        {Auth.getUserType() == "ADM" && mystate.farmDefaults.can_use_invoices && (
          <Link to={`${props.match.url}/manualInvoice`}>
            <ButtonOrange label={"Create Invoice"} />
          </Link>
        )}
        {1 == 2 && <button onClick={() => offlinePayment()}>Offline</button>}
      </div>
      <div
      className="cancelButtonContainer"
        style={{
          display: "flex",
          justifyContent: "center",
          flex: "1 1 100%",
        }}
      >
        <ButtonBlue onClick={() => cancelCheckout()} label={"Cancel"} />
      </div>
    </div>
  );
};

export default OnlinePurchase;
