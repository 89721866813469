import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { ListContext, MyContext } from "./App";
import COLORS from "./styleConstants";
import AuthHelperMethods from "./AuthHelperMethods";
import { XrefSelect } from "./xrefs";
import { UserCan, ModalHeaderBlue, ButtonOrangeSm, ModalWarning } from "./formComponents";
import { EditIconPlain } from "./icons";
import PrivateRoute from "./privateRoute";
import { ClientAvatar } from "./images";
import StudentForm from "./studentForm";
import MemberLinks from "./clientHomePage";


import { calculateAge } from "./momentUtils";
const Auth = new AuthHelperMethods();

const StudentInfo = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  let history = useHistory();
  const [active, setActive] = useState(true); //used as state flag for student_option useEffect
  const [currentValue, setCurrentValue] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [activeWord, setActiveWord] = useState("active");
  

  const [warning, setWarning] = useState(
    `You are about to ${activeWord} this student. They will no longer appear in the dropdown list where you choose students.`
  );

  useEffect(
    () => {
      setActiveWord(listState.accountHolder.is_active ? "Inactivate" : "Activate");
    },
    //eslint-disable-next-line
    []
  );

  const warning2 = `If you wanted to ${activeWord} all the members on the account, go to the Account Tab.`;

  console.log(props.match.url);
  /* const clickInactivate = () => {
    let moreMembers = listState.members.length > 1;
    let isAlsoAccountHolder = listState.currentClient.id == listState.accountHolder.id;

    setShowWarning(true);
  };
*/

  /*
  const Inactivate = async () => {
    let moreMembers = listState.members.filter((member) => member.is_active).length > 1;
    let isAlsoAccountHolder = listState.currentClient.id == listState.accountHolder.id;

    let params = {};
    params.studentsIds = [listState.currentClient.student_id];
    //params.is_active = listState.accountHolder.is_active ? 1 : 0;
    params.whattodo = listState.currentClient.is_active ? "inactivate" : "activate";

    if (isAlsoAccountHolder) {
      if (!moreMembers) params.studentPeopleIds = [listState.currentClient.id];
      // if other members, don't need to inactivate person 
   }

    if (!isAlsoAccountHolder) {
      params.studentPeopleIds = [listState.currentClient.id];
      //if (moreMembers) params.studentPeopleIds = [listState.currentClient.id];
      //if (!moreMembers && listState.accountHolder.is_student) params.studentPeopleIds = [listState.currentClient.id];
      if (!moreMembers && !listState.accountHolder.is_student) params.accountHolderId = [listState.accountHolder.id];
    }

    //  params.studentPeopleIds = [listState.currentClient.id];

    try {
      const result = await Auth.api("people/updateIsActiveAccount", "post", params);
      dispatch2(["CLIENT_GET", result.data.currentClient]);

      dispatch2(["CLIENT_GET_ACCOUNT", result.data]);
    } catch {
      console.log("error");
    }
    // const result = await Auth.api("students/updateIsActive", "post", params);
    // console.log(result);
    // dispatch2(["CLIENT_GET", result.data.client]);

    // result && setActive(!listState.clientdata.is_active);
    // setActive(!active);
    // let data = { data: result.data.student };

    //dispatch2(["CLIENT_DATA", data]);

    alert(
      `Client ${listState.clientdata.person_name} has been ${
        listState.clientdata.is_active ? "inactivated" : "activated"
      }`
    );
    // dispatch2(["CLIENT_GET", result.data.client]);
    // history.push("/admin/clients");
  };

  */
  useEffect(
    () => {
      console.log(listState.members.length);
      if (listState.members.length == 0) {
        console.log("no members");
        // history.push(`admin/clienthome/${listState.accountHolder.}/account`);
      } else {
        console.log("yeah members");
      }
    },
    //eslint-disable-next-line
    [listState.members]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        /* console.log(listState.members.length);
        if (listState.members.length == 0) {
          console.log("no members");
        } else {
          console.log("yeah members");
        }*/
        try {
          dispatch({ type: "FETCH_INIT" });

          let params = { person_id: listState.currentClient.id };
          const result = await Auth.api("students/student", "get", params);
          let data = { data: result.data.student };
          dispatch2(["CLIENT_DATA", data]);
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          dispatch({ type: "FETCH_ERROR" });

          console.error(error.message);
        }
        return () => {
          dispatch2(["CLIENT_DATA", []]);
        };
      };
      fetchData();
    },
    //eslint-disable-next-line
    [listState.currentClient.id]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_INIT" });

          const result = await Auth.api("students/index", "get");
          dispatch({
            type: "GET_STUDENTS",
            payload: result.data.students,
            options: result.data.students.map((item) => {
              return { value: item.id, label: item.person_name };
            }),
          });
          dispatch({ type: "FETCH_SUCCESS" });
        } catch (error) {
          console.log("error");
          dispatch({ type: "FETCH_ERROR" });
        }
      };
      fetchData();
    },
    //eslint-disable-next-line
    [active]
  );

  if (!listState.clientdata) return null;

  return (
    <>
      <div
        className="studentInfo"
        style={{
          display: "flex",
          flex: "1 1 100%",
          justifyContent: "center",
          flexDirection: "row",
          borderx: "solid red",
          flexWrap: "wrap",
        }}
      >
        {showWarning && (
          <ModalWarning
            title={activeWord}
            proceedlabel={`Yes, ${activeWord} student`}
            cancellabel={`No, I do not wish to ${activeWord} student`}
            warningMessage={warning}
            warning2={warning2}
            cancel={() => setShowWarning(false)}
            proceed={"yes"}
          ></ModalWarning>
        )}
        <div
          className="studentdetails"
          style={{
            display: "flex",
            flex: "1 1 50%",
            maxWidthx: 600,
            borderx: "solid 5px lightgrey",
            padding: 10,
            marginTop: 15,
            flexWrap: "wrap",
            alignContent: "flex-start",
          }}
        >
          <div
            className="header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: "1 1 100%",
              marginBottom: 30,
            }}
          >
            <div>
              {" "}
              <ClientAvatar height={70} width={70} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: COLORS.textgrey,
                fontSize: 20,
                marginTop: 20,
              }}
            >
              <span style={{ fontSize: "14pt", cursor: "pointer", color: COLORS.blue }}>
                {listState.clientdata.person_name}
              </span>

              <div>{listState.accountHolder.contact_email}</div>
              <div>{listState.accountHolder.contact_mobile && listState.accountHolder.contact_mobile}</div>
              <div>{listState.accountHolder.contact_homephone && listState.accountHolder.contact_homephone}</div>
            </div>
          </div>

          <div
            className={"details"}
            style={{
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ fontSize: 20, marginRight: 30 }}>
                {" "}
                {`Status ${listState.clientdata.is_active ? "active" : "inactive"}`}
              </div>
            </div>
            <UserCan isThis={"ADM"}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                {" "}
                <div
                  style={{ fontSize: 20, marginTop: 10, marginRight: 20, color: COLORS.orange }}
                >{`Edit Student Details`}</div>
                <Link to={`${props.match.url}/edit`}>
                  <EditIconPlain fontSize={"20px"} />
                </Link>
              </div>
            </UserCan>
            <div
              className="studentBox"
              style={{
                borderx: "solid 2pt lightgrey",
                borderLeft: "red",
                borderRadius: 10,
                padding: 5,
                width: 300,

                fontSize: 20,

                marginBottom: 5,
              }}
            >
              {listState.clientdata.general_notes1 && (
                <>
                  <div style={{ fontSize: 20, marginTop: 10 }}>{`General Notes`}</div>

                  <div style={{ marginBottom: 5, whiteSpace: "pre-wrap" }}>{listState.clientdata.general_notes1}</div>
                </>
              )}
              {listState.clientdata.general_notes2 && (
                <div style={{ marginBottom: 5 }}>{listState.clientdata.general_notes2}</div>
              )}
              {listState.clientdata.general_notes3 && (
                <div style={{ marginBottom: 5 }}>{listState.clientdata.general_notes3}</div>
              )}
              {listState.clientdata.date_of_birth && (
                <div style={{ fontSize: 20, marginTop: 10 }}>{`Date of Birth`}</div>
              )}
              <div style={{ marginBottom: 5 }}>{listState.clientdata.date_of_birth}</div>
              <div style={{ marginBottom: 5 }}>
                {listState.clientdata.date_of_birth && `age ${calculateAge(listState.clientdata.date_of_birth)}`}
              </div>

              {listState.clientdata.riding_level && (
                <>
                  {" "}
                  <div style={{ fontSize: 20, marginTop: 10 }}>{`Level`}</div>
                  <div style={{ marginBottom: 5 }}>{listState.clientdata.riding_level}</div>
                </>
              )}
              {listState.clientdata.tack && (
                <>
                  <div style={{ fontSize: 20, marginTop: 10 }}>{`Tack`}</div>
                  <div style={{ marginBottom: 5 }}>{listState.clientdata.tack}</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="privs"
          style={{
            display: "flex",
            flex: "1 1 50%",
            flexDirection: "column",
            marginTop: 50,
            alignContent: "flex-start",
          }}
        >
          <UserCan isThis={"ADM"}>
            <div className={"studenthorses"}>
              <XrefSelect
                options={mystate.options_horse}
                get={"xrefs/getStudentHorses"}
                post={"xrefs/updateStudentHorse"}
                single={listState.currentClient.student_id}
                label={`Can view these horses`}
                nametag
                horse
              />
            </div>
          </UserCan>

          <UserCan isThis={"STU"} ifFarm={"clients_can_view_horses"}>
            <div className={"studenthorses"}>
              <XrefSelect
                options={mystate.options_horse}
                get={"xrefs/getStudentHorses"}
                post={"xrefs/updateStudentHorse"}
                single={listState.currentClient.student_id}
                label={"Horses"}
                nametag
                horse
              />
            </div>
          </UserCan>
          <UserCan isThis={"ADM"} ifFarm={"restrict_client_sections"}>
            <XrefSelect
              options={mystate.options_section}
              get={"xrefs/getStudentSectionsBook"}
              post={"xrefs/updateStudentSectionBook"}
              single={listState.currentClient.student_id}
              label={`Can book these sections:`}
              nametag
              section
              name
            />
          </UserCan>
          <UserCan isThis={"ADM"} ifFarm={"restrict_client_add_sections"}>
            <XrefSelect
              options={mystate.options_section}
              get={"xrefs/getStudentSectionsAdd"}
              post={"xrefs/updateStudentSectionAdd"}
              single={listState.currentClient.student_id}
              label={`Can create events with these sections:`}
              nametag
              sectionadd
            />
          </UserCan>
          {1 == 2 && (
            <>
              {" "}
              <XrefSelect
                options={mystate.options_instructor}
                get={"xrefs/getStudentInstructors"}
                post={"xrefs/updateStudentInstructor"}
                single={listState.currentClient.student_id}
                label={"Assigned Staff"}
                nametag
                staff
              />
              <XrefSelect
                options={mystate.options_product}
                get={"xrefs/getStudentProducts"}
                post={"xrefs/updateStudentProduct"}
                single={listState.currentClient.student_id}
                label={`${listState.currentClient.person_name}  may purchase these packages online:`}
              />
            </>
          )}
        </div>

        <PrivateRoute path={`${props.match.url}/edit`} component={StudentForm} />
      </div>
    </>
  );
};
export default StudentInfo;
