import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, useHistory, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";
import COLORS from "./styleConstants";

export const Updates = () => {
  const isMobile = window.innerWidth < 500;
  console.log(isMobile);
  return (
    <div className="updateContainer" style={{ padding: isMobile ? 0 : 100 }}>
      <div>
        <h1 style={{ color: COLORS.orange }}>{"Updates"}</h1>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          flexDirection: "column",
          marginTop: 10,
          fontSize: 14,
        }}
      >
         <div style={{ marginBottom: 20, fontSize: 20 }}>
          <b>{"March 21, 2025"}</b>
        </div>
        <div>
          <div>
         
            <h5 style={{ color: COLORS.blue }}>Additional Staff Permission For Roles</h5>
            <p>
              <b>Staff Can Add Other Staff To Events </b>
              You can now allow your staff to assign staff other than themselves to calendar events. You will find this permission in Setup - Roles at the bottom of the permissions list. If you have staff that you have had to be made an admin until now in order to do this, you can change them back to a regular staff role (send a support request if you would like this done for you).
            </p>
            <h5 style={{ color: COLORS.blue }}>Improvements to Mobile Views on Client Pages</h5>
            <p>
              All the client pages have been improved for mobile views.  The card payment screens have been adjusted, as well as the Packages, Invoices, and Payments pages.  
            </p>
            <h5 style={{ color: COLORS.blue }}>Additional Field on Reports</h5>
            <p>Under Reports, the Student Detail Report and the Staff By Student Report now have a column called "Makeup" which indicates that lesson is a makeup for the student.</p>

            </div>


            <h3 style={{ color: COLORS.blue  , marginTop:20}}>Coming April 2025 - Recurring Payments!</h3>

        </div>
       
    </div>
    </div>
  );
};
