import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Link, useParams, useHistory, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Modal, Accordion, Dropdown } from "react-bootstrap";
import { ListContext } from "./App";
import AuthHelperMethods from "./AuthHelperMethods";
import {
  BlueNavLink12,
  PrimaryText,
  PrimaryText18,
  PrimaryTextBlue,
  SaveButtonSm,
  CancelButtonSm,
  PageTitle,
  BlueText,
  HelpText,
  BlockLabelGrow,
} from "./styleCustom";

import {
  currentDayToDB,
  is_expired,
  calculate_expiry_date,
  timeISOtoString,
  timeISOtoStringLocal,
  timeISOtoStringUtc,
  dateISOtoStringMed,
  dateISOtoStringUtc,
  dateISOtoStringMedLocal,
  dateISOtoStringMedUTC,
  dateTimeISOtoStringMed,
  dateTimeISOtoStringMedLocal,
  dateTimeISOtoStringMedUTC,
  timeISOtoStringN,
} from "./momentUtils";
import { PinIcon, Private, Public, Expand } from "./icons";

import OnlinePurchase from "./onlinePurchase";
import COLORS from "./styleConstants";
import { ModalHeaderBlue, UserCan, BackTo } from "./formComponents";
import AddNote from "./addNote1"; //from ***addNote1****

const Auth = new AuthHelperMethods();

export const PinIconDiv = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  const togglePin = (note) => {
    let params = { pin: !note.pin, id: note.id };
    dispatch2(["UPDATE_CLIENT_NOTE_PIN", note.id]);
    const result = Auth.api(props.controller, "post", params);
    // dispatch2(["UPDATE_CLIENT_NOTE_PUBLIC", note.id]);
    console.log(result.data);
  };

  return (
    <>
      <div
        className="pin"
        {...(!profileOnly && { onClick: () => togglePin(props.note) })}
        style={{
          display: "flex",

          justifyContent: "flex-end",
          zIndex: 1000,
          alignItems: "flex-start",
          padding: 5,
        }}
      >
        {!profileOnly && <PinIcon fontSize={"20px"} color={props.note.pin ? COLORS.orange : COLORS.medgrey} />}
        {profileOnly && props.note.pin && <PinIcon fontSize={"20px"} color={COLORS.orange} />}
      </div>
    </>
  );
};

const NotesTable = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

  let location = useLocation();
  const profileOnly = location.pathname.startsWith("/admin/calendarGroup/edit");

  let notes = listState.clientdata || [];
  console.log(notes);
  const usertype = Auth.getUserType();
  let curparams = useParams();
  let horse = curparams.horse;
  console.log(horse);
  let controller = horse ? "notes_horses/updatePublic" : "notes_clients/updatePublic";

  const togglePublic = (note) => {
    let params = { is_public: !note.is_public, id: note.id };
    dispatch2(["UPDATE_CLIENT_NOTE_PUBLIC", note.id]);

    const result = Auth.api(controller, "post", params);
    // dispatch2(["UPDATE_CLIENT_NOTE_PUBLIC", note.id]);
    console.log(result.data);
  };

  const PublicPrivateIcon = (props) => {
    if (usertype == "STU") return null;
    return (
      <div {...(!profileOnly && { onClick: () => togglePublic(props.note) })}>
        {!props.note.is_public ? <Private fontSize={"20px"} /> : <Public fontSize={"20px"} />}
      </div>
    );
  };

  const LessonInfo = (props) => {
    //   console.log(props);
    //  console.log(props.note);

    if (!props.note) return null;
    // if (!notes) return null;
    // return null;
    return (
      props.note.lesson_name && (
        <div className={"1.5"} style={blockStyle}>
          {" "}
          {props.note.scheduled_date &&
            ` Regarding ${props.note.lesson_name} on 
        ${dateISOtoStringMed(props.note.scheduled_date)}`}
        </div>
      )
    );
  };

  const DateInfo = (props) => {
    return (
      <>
        <div className={"row1"} style={blockStyle}>
          {dateISOtoStringMedLocal(props.note.created_at)}
        </div>
      </>
    );
  };

  const blockStyle = {
    paddingx: 10,
    color: "#9a94a5",
    paddingLeft: 0,
    flexx: " 1 0",
    minWidth: 150,
  };

  if (!notes) return null;
  if (!notes.length > 0) return null;

  return (
    <>
      <Route path={`${props.match.url}/addNote/:isPublic/:who`} component={AddNote} />
      <div
        className="pageWrapper"
        style={{
          borderBottom: "solid 1pt lightgrey",
          display: "flex",
          flex: "1 1 100%",
          paddingBottom: 30,
          paddingTop: 30,

          flexWrap: "wrap",
          background: "#FBFAFA ",
        }}
      >
        {notes &&
          notes.map((note) => (
            <div
              className={"eachrow"}
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: 10,
                borderx: "solid 1pt lightgrey",
                minHeight: 50,
                flex: "1 1 100%",
                flexDirection: "row",

                paddingRight: 20,
              }}
              key={note.id}
            >
              <div
                className="restAfterIcon"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: "1 1  100% ",
                  marginLeft: 100,
                  flexDirection: "column",
                }}
              >
                <DateInfo note={note} />
              </div>

              <div
                className="r2"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: "1 1  100% ",
                }}
              >
                <div
                  className="iconColumn"
                  style={{
                    display: "flex",
                    width: isMobile ? 50 : 100,
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 5,
                    border: "blue",
                  }}
                >
                  <PinIconDiv note={note} controller={controller} />
                  <PublicPrivateIcon note={note} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: "1 1 ",
                    flexWrap: "wrap",
                    border: "solid 1pt darkgrey",
                    background: "white",
                    padding: 10,
                  }}
                  className={"row2"}
                >
                  {note.lesson_name && <LessonInfo note={note} />}
                  <div style={{ display: "flex", flex: " 1 1 100%" }}>
                    {" "}
                    <div style={{ color: COLORS.blue, whiteSpace: "pre-wrap" }}>{note.notes}</div>
                  </div>
                  <div
                    className={"row3"}
                    style={{
                      justifyContent: "flex-end",
                      fontStyle: "italic",
                      color: "#9a94a5",
                      display: "flex",
                      flex: "1 1 100%",
                      fontSize: "x-small",
                    }}
                  >
                    {note.created_by_name || note.created_by_person} {timeISOtoStringLocal(note.created_at)}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default NotesTable;
