import React, { useState, useContext, useReducer } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import APIURL from "./Constants2";
import decode from "jwt-decode";
import AuthHelperMethods from "./AuthHelperMethods";
import { MyContext } from "./App";
import { ListContext } from "./App";
import { Formik, Form, useField, useFormikContext, FieldProps, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { StyledErrorMessage, OrangeButtonSm, PrimaryTextBlue, PageTitle, HelpText, GreyText } from "./styleCustom";
//import LogRocket from "logrocket";
import posthog from "posthog-js";

import initialStateList from "./initialStateList";
import listPageReducer from "./listPageReducer";
import initialState from "./initialState";
import calendarReducer from "./calendarReducer";
import { ButtonOrange } from "./formComponents";
import { Logo } from "./images";
//import { LockClosedIcon } from "@heroicons/react/20/solid";
//const APIURL = process.env.REACT_APP_APIURL;
//const APIURL = process.env.NODE_ENV == "development" ? "http://localhost:3001/" : process.env.REACT_APP_APIURL;

const Auth = new AuthHelperMethods();

function LoginContainer({ children, label }) {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        padding: "12 4 12 4",
        margin: 5,
      }}
    >
      <div style={{ width: "100%", maxWidth: 448 }} className="w-full max-w-md space-y-8">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10",
          }}
        >
          <Logo />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{label}</h2>
          <p className="mt-2 text-center text-sm text-gray-600"></p>
        </div>
        {children}
      </div>
    </div>
  );
}

function EmailInput({ label, userName, placeholder = "Email address", setUserName = () => {} }) {
  return (
    <div>
      <input
        type="username"
        value={userName}
        onChange={(e) => {
          setUserName(e.target.value);
        }}
        placeholder={placeholder}
        style={{
          width: "100%",
          border: "solid",
          borderColor: "#d1d5db",
          padding: 10,
          color: "#111827",
          borderRadius: 5,
          fontSize: 16,
          borderWidth: 1,
          marginBottom: 1,
        }}
      />
    </div>
  );
}

function PasswordInput({ password, setPassword = () => {} }) {
  return (
    <div>
      <label htmlFor="password" className="sr-only">
        Password
      </label>
      <input
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        placeholder="Password"
        style={{
          width: "100%",
          border: "solid",
          borderColor: "#d1d5db",
          padding: 10,
          color: "#111827",
          borderRadius: 5,
          fontSize: 16,
          borderWidth: 1,
        }}
      />
    </div>
  );
}

function LoginButton({ handleClick = () => {}, buttonLabel = "Sign In" }) {
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 10 }}>
      <ButtonOrange onClick={(e) => handleClick(e)} label={buttonLabel} />
    </div>
  );
}
function Login(props) {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [usercode, setUserCode] = useState();

  let history = useHistory();
  console.log("do you come here often?");
  const getUserPrivs = async (role_id, user_code) => {
    let url = user_code == "ADM" ? "permissions/getPermissionCodes" : "role_permissions/getPermissionsforRole";
    let params = { role_id: role_id };
    const result = await Auth.api(url, "get", params);
  };

  const goToPage = () => {
    let token = Auth.getToken();
    let user_code = decode(token).user_type_code;
    let user_person_id = decode(token).user_person_id;

    if (user_code === "ADM") {
      console.log("ADM");
      history.push("/admin/daysheet");
    }
    if (user_code === "INS") {
      dispatch2(["LOGGED_IN_STAFF", user_person_id]);
      history.push(`/admin/staffhome/${user_person_id}/schedule`);
    }

    if (user_code === "STU") {
      history.push(`/admin/clienthome/${user_person_id}/account`);
    }

    if (user_code === "SPR") {
      history.push(`/admin/superuser`);
    }
  };

  const postLogin = async (e) => {
    e.preventDefault();
    dispatch({ type: "FETCH_INIT" });
    const user = { email: userName, password: password };

    try {
      const result = await axios.post(`/authenticate`, user);
      let token = result.data.auth_token;
      localStorage.setItem("auth_token", result.data.auth_token);
      setLoggedIn(true);
      //const { role_id, user_type_code, user_person_id, username } = decode(token);
      let role_id = decode(token).role_id || null;
      let user_code = decode(token).user_type_code;
      let user_person_id = decode(token).user_person_id;
      let userName = decode(token).username;

      posthog.identify(userName);

      if (role_id) getUserPrivs(role_id, user_code);
      console.log(listState);
      goToPage(user_code, user_person_id);
    } catch (error) {
      setIsError(true);
      dispatch({ type: "FETCH_ERROR" });
    }
  };
  // if (localStorage.getItem("auth_token"))
  if (Auth.loggedIn()) {
    goToPage();
  } else {
    Auth.setToken("");
  }

  const reset = () => {
    //  localStorage.setItem("auth_token", "");
  };

  return (
    <>
      <LoginContainer label={"Sign into your account"}>
        <form>
          <div style={{ boxShadow: " 0 1px 2px 0 rgb(0 0 0 / 0.05)", borderRadius: 10 }}>
            <EmailInput setUserName={setUserName} userName={userName} />
            <PasswordInput setPassword={setPassword} password={password} />
          </div>

          <div
            className="flex items-center justify-between"
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <div className="text-sm">
              <Link to={"/password"}>Change/Forgot Password</Link>
            </div>
          </div>
          {isError && <div style={{ color: "red" }}>The username or password provided were incorrect.</div>}
          <LoginButton handleClick={postLogin} />
        </form>
      </LoginContainer>
    </>
  );
}

export const CreatePasswordFromToken = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  let location = useLocation();
  let history = useHistory();
  console.log(location.pathname);
  let last = location.pathname.lastIndexOf("/");
  let token = location.pathname.substring(last + 1);
  const [success, SetSuccess] = useState(false);
  let params = { token: token, email: "", password: "" };
  return (
    <LoginContainer label={"Create New Password"}>
      <Formik
        initialValues={{ token: token, password: "" }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("No password provided.")
            .min(8, "Password is too short - should be 8 chars minimum.")
            .matches(/^(?=.*[0-9])(?=.*[a-z])/i, "must contain at least one number and one letter"),
        })}
        onSubmit={async (values, { setSubmitting, errors, touched }) => {
          try {
            const result = await axios.post(`${APIURL}passwords/create`, values);
            setSubmitting(false);
            if (result.data.success == "success") history.push("/login");
          } catch (e) {
            setIsError(true);
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form id="reset">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 30,
              }}
            >
              <PageTitle>create password</PageTitle>

              <GreyText>Password</GreyText>
              <HelpText>must be minimum 8 characters and contain at least one letter and one number</HelpText>

              <Field name="password" placeholder="new password - minimum 8 characters" />
              <ErrorMessage name="password" component={StyledErrorMessage} />
              <OrangeButtonSm type="submit">Send</OrangeButtonSm>
              {isError && <div style={{ color: "red" }}> Invalid Token</div>}
            </div>
          </Form>
        )}
      </Formik>
    </LoginContainer>
  );
};

export const ValidatePasswordToken = (props) => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  let location = useLocation();
  let history = useHistory();
  console.log(location.pathname);
  let last = location.pathname.lastIndexOf("/");
  let token = location.pathname.substring(last + 1);
  const [success, SetSuccess] = useState(false);
  let params = { token: token, email: "", password: "" };
  return (
    <LoginContainer label={"Create New Password"}>
      {" "}
      <Formik
        initialValues={{ token: token, email: "", password: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("email required"),
          password: Yup.string()
            .required("No password provided.")
            .min(8, "Password is too short - should be 8 chars minimum.")
            .matches(/^(?=.*[0-9])(?=.*[a-z])/i, "must contain at least one number and one letter"),
        })}
        onSubmit={async (values, { setSubmitting, errors, touched }) => {
          try {
            const result = await axios.post(`${APIURL}passwords/reset`, values);
            setSubmitting(false);
            if (result.data.success == "success") history.push("/login");
          } catch (e) {
            setIsError(true);
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form id="reset">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 30,
              }}
            >
              <GreyText>Email</GreyText>
              <Field name="email" placeholder="email" type="email" />

              <ErrorMessage name="email" component={StyledErrorMessage} />
              <GreyText>Password</GreyText>
              <HelpText>must be minimum 8 characters and contain at least one letter and one number</HelpText>

              <Field name="password" placeholder="new password - minimum 8 characters" />
              <ErrorMessage name="password" component={StyledErrorMessage} />
              <OrangeButtonSm type="submit">Reset</OrangeButtonSm>
              {isError && <div style={{ color: "red" }}> Invalid Email or Token</div>}
            </div>
          </Form>
        )}
      </Formik>
    </LoginContainer>
  );
};

export const ChangePassword = () => {
  const { mystate, dispatch } = useContext(MyContext);
  const { listState, dispatch2 } = useContext(ListContext);
  const [userName, setUserName] = useState("");
  const [isError, setIsError] = useState(false);
  const [emailSent, SetEmailSent] = useState(false);

  const handleChangePassword = async () => {
    setIsError(false);
    SetEmailSent(false);

    let params = { email: userName };
    try {
      const result = await axios.post(`${APIURL}passwords/forgot`, params);
      SetEmailSent(true);
    } catch (e) {
      setIsError(true);
    }
  };

  return (
    <>
      <LoginContainer label={"Request a password change"}>
        <EmailInput setUserName={setUserName} userName={userName} />
        {isError && <div style={{ color: "red" }}>Could not find account for email address</div>}
        <LoginButton
          handleClick={handleChangePassword}
          buttonLabel={"Send Request"}
          placeholder={"Enter the email address associated with your account"}
        />
        {emailSent && (
          <div style={{ color: "blue" }}>A link to change your password has been sent to this email address.</div>
        )}
      </LoginContainer>
    </>
  );
};

export function WaiverNewAccountRedirect() {
  return (
    <LoginContainer label={"Login instructions have been sent to your email address."}>
      <div></div>
    </LoginContainer>
  );
}

export default Login;
