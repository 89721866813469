import React, { useEffect, useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Route, Switch, useHistory, Link } from "react-router-dom";

import AuthHelperMethods from "./AuthHelperMethods";
import { ListContext, MyContext } from "./App";

import { PrimaryTextBlue, BlueButton } from "./styleCustom";
import { Modal } from "react-bootstrap";
import { todayDisplay } from "./momentUtils";
import COLORS from "./styleConstants";
import "./elementsStyle.css";
import { BlueButtonReg } from "./formComponents";
import OnlinePurchase from "./onlinePurchase";
import { CreatePaymentAndItems } from "./createPaymentAndItems";
import { Square, CheckSquare } from "./icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Succeeded, PaymentButtons } from "./paymentSavedForm";

const Auth = new AuthHelperMethods();

export const CheckoutForm = ({ isInvoicePayment = false, paymentId = "", currentPage = "purchases" }) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const { mystate, dispatch } = useContext(MyContext);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [ready, setReady] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
   const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  
  const history = useHistory();
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    console.log(event.target);
    if (isEmpty) {
      console.log("NOTHING");
      alert("PLEASE FILL IN  YOUR CARD DETAILS");
      return null;
    }

    dispatch({
      type: "FETCH_INIT",
    });
    setProcessing(true);
    //todo see if this is an issue for saved payments
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    var payload;
    //  const stripe = useStripe();
    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    //  const result = await Auth.api("payments/getFarmAccountId", "get");

    try {
      payload = await stripe.confirmCardPayment(listState.paymentIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        setup_future_usage: saveCard ? "on_session" : null, //!on_session allows card to be saved for when client is present - todo check re setup intent off session
      });
      if (saveCard) {
        let params = { person_id: listState.accountHolder.id, payment_method: payload.paymentIntent.payment_method };
        try {
          const result = await Auth.api("payments/updatePaymentMethod", "post", params);
        } catch (e) {
          console.log(e);
        }
      }
      setError(null);
      //  setSucceeded(true);
      setProcessing(false);
      dispatch2([
        "SET_CLIENT_PAYMENT",
        { ...listState.clientPayment, transaction_type: "online", reference: payload.paymentIntent.id },
      ]);
    } catch {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      console.log("[error]", payload.error);
      alert(payload.error.message);
      dispatch({
        type: "FETCH_ERROR",
      });
    }
  };

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const paymentFormStyle = {
    maxWidth: 500,
    display: "flex",
    flex: "1 1 100%",
    justifyContent: "center",
    flexDirection: "column",
    margin:  isMobile ? 10 : 50,
  };
  const checkDivStyle = { margin: 10, padding: 20, fontSize: 14, display: "flex", alignItems: "center" };
  const stripeInputIsReady = (el) => {
    el.focus();
    dispatch({
      type: "FETCH_SUCCESS",
    });
    return null;
  };
  const finishPay = () => {
    dispatch({
      type: "FETCH_SUCCESS",
    });
    setSucceeded(true);
  };

  const handleElementChange = (event) => {
    if (event.error) {
      console.log(event.error);
      console.log("DON'T PROCEED");
    }
    if (!event.empty) setIsEmpty(false);
    console.log(event);
  };
  return (
    <>
      <div className="checkout-form">
        <div className="sr-payment-form">
          <div className="sr-form-row" />
          <Modal show={true} centered backdrop="static">
            {!succeeded && (
              <form onSubmit={handleSubmit} className="payment" style={paymentFormStyle}>
                <div className="sr-combo-inputs" style={{ border: "solid 1 grey", width: "100%" }}>
                  <>
                    <div style={checkDivStyle}>
                      <div onClick={() => setSaveCard(!saveCard)} style={{ marginRight: 10, fontSize: 20 }}>
                        {saveCard ? <CheckSquare fontSize={30} /> : <Square fontSize={30} />}
                      </div>
                      <div style={{ fontSize: 14, color: COLORS.textDark }}>
                        {`Would you like to save this payment method for future use?`}
                      </div>
                    </div>
                    <div className="sr-combo-inputs-row">
                      <CardElement
                        onReady={(el) => stripeInputIsReady(el)}
                        className="payment sr-input sr-card-element"
                        options={options}
                        onChange={(event) => handleElementChange(event)}
                      />
                    </div>
                  </>
                </div>

                {error && <div className="message sr-field-error">{error}</div>}
                <PaymentButtons processing={processing} />
              </form>
            )}
            {succeeded && <Succeeded />}
          </Modal>
        </div>
      </div>
      {Object.keys(listState.clientPayment).length > 0 && (
        <CreatePaymentAndItems onFinish={finishPay} isInvoicePayment={isInvoicePayment} paymentId={paymentId} />
      )}
    </>
  );
};

export default CheckoutForm;
