import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { BlockLabel, StyledList, StyledListGroup, BlueButton, BlockLabelNoMargin } from "./styleCustom";
import { FormRightHeader, FormRight } from "./formComponents";
import { Row, Col } from "react-bootstrap";
import SelectList from "./selectList";
import { ListContext } from "./App";
import { RemoveIconNoPosition, TimesIconBlue } from "./icons";
import AuthHelperMethods from "./AuthHelperMethods";
import Select from "react-select";
import SelectTemplateFilter from "./searchFilterReports";
import { CheckSquare, Square } from "./icons";
import { listItemStyle, boxStyle } from "./styleConstants";

const Auth = new AuthHelperMethods();

const RoleAssignPermission = (props) => {
  const { listState, dispatch2 } = useContext(ListContext);
  const [assignedArray, SetAssignedArray] = useState([]);
  const [roleName, setRoleName] = useState("");
  let role_id = useParams().id;
  let history = useHistory();

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("roles/index", "get");
        dispatch2(["ROLES_GET", result.data]);
      };
      //   if (!listState.roles.length) fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const fetchData = async () => {
        const result = await Auth.api("role_permissions/index", "get");
        if (result) dispatch2(["ROLE_PERMISSIONS_GET", result.data]);
      };
      //  if (!listState.rolePermissions.length ) fetchData();
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("useeffet");
      const fetchData = async () => {
        let params = {};
        const result = await Auth.api("permissions/index", "get", params);
        dispatch2(["PERMISSIONS_GET", result.data]);
      };
      //  if (!listState.permissionList) fetchData();

      //  }
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      SetAssignedArray(
        listState.rolePermissions
          .filter((item) => item.role_id == role_id)
          .map((permission) => permission.permission_id)
      );
    },
    //eslint-disable-next-line
    [listState.rolePermissions, role_id]
  );
  useEffect(
    () => {
      let curname = {};
      // alert("rolename");

      curname = listState.roles.find((role) => role.id == role_id);
      if (curname) setRoleName(curname.role_name);
    },
    //eslint-disable-next-line
    [role_id]
  );
  const unassign = async (objectIn) => {
    console.log(objectIn);
    // dispatch2(["ROLE_PERMISSION_DELETE", objectIn.pk]);
    let params = { permission_id: objectIn.id, role_id: role_id };
    console.log(params);
    dispatch2(["ROLE_PERMISSION_DELETE", params]);
    let result = await Auth.api("role_permissions/delete", "post", params);
  };

  const addPermissionToRole = async (permissionobj) => {
    let params = {};
    params.role_id = role_id;
    params.permission_id = permissionobj.id;
    try {
      let result = await Auth.api("role_permissions/create", "post", params);

      dispatch2(["ROLE_PERMISSION_CREATE", result.data]);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClose = () => {
    goBackToList();
  };
  function goBackToList() {
    history.goBack();
  }

  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 100%",
        minWidth: 700,
        justifyContent: "center",
        marginBottom: 100,
      }}
    >
      {" "}
      <div className="Border">
        <div
          className="header"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flex: "1 1 100%",
            justifyContent: "center",
          }}
        >
          <FormRightHeader>{`Assign Permissions to ${roleName} Role`}</FormRightHeader>
        </div>
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <div
            className="left"
            style={{
              display: "flex",
              flexWrap: "wrap",
              flex: "1 1 50%",
              alignContent: "flex-start",
              padding: 10,
              marginRight: 60,
            }}
          >
            <BlockLabelNoMargin> assigned permissions </BlockLabelNoMargin>
            {assignedArray.length < 1 && <p>No Permissions Assigned</p>}
            {assignedArray.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexDirection: "column",
                }}
              >
                {listState.permissionList.map((item) =>
                  assignedArray.includes(item.id) ? (
                    <div
                      key={item.id}
                      onClick={() => unassign(item)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 5,
                      }}
                    >
                      {item.name} <RemoveIconNoPosition />
                    </div>
                  ) : null
                )}
              </div>
            )}
          </div>
          <div className="right" style={{ display: "flex", flex: "1 1 50%" }}>
            {listState.permissionList && listState.permissionList.length > 0 && (
              <div>
                {listState.permissionList.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => addPermissionToRole(item)}
                    style={{ display: "flex", marginBottom: 10 }}
                  >
                    {assignedArray.includes(item.id) ? <CheckSquare /> : <Square />}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 20,
                          fontWeight: "bold",
                          color: ["STAFF_RESTRICT_VIEW_CALENDAR_SELF", "STAFF_RESTRICT_ADD_STUDENTS_SELF"].includes(item.code) && "red",
                        }}
                      >
                        {`${["STAFF_RESTRICT_VIEW_CALENDAR_SELF", "STAFF_RESTRICT_ADD_STUDENTS_SELF"].includes(item.code) ? "*RESTRICTION*    "  + item.name  : item.name}`}
                      </div>
                      {item.description && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 20,
                            fontStyle: "italic",
                          }}
                        >
                          {" "}
                          {item.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <Row style={{ justifyContent: "center", display: "flex", marginTop: 30 }}>
          <BlueButton onClick={() => goBackToList()}>{"Close"} </BlueButton>
        </Row>
      </div>
    </div>
  );
};

export default RoleAssignPermission;
